<template>
  <button class="btn btn-outline-primary" @click="copySnippet()">
    <i class="bi bi-code" />
    Copy Embed Codes
  </button>
</template>

<script lang="ts">
export default {
  name: 'CopyEmbedCode',
  props: {
    snippet: {
      type: String,
      required: true,
    },
  },
  methods: {
    copySnippet() {
      navigator.clipboard.writeText(this.snippet)
      this.$toast('Embed Code copied to clipboard')
    },
  },
}
</script>
