<template>
  <button class="btn btn-outline-primary" @click="copySnippet()">
    <i class="bi bi-share" />
    Copy Share Link
  </button>
</template>

<script lang="ts">
import { generateShareLink } from '@lib/share_link'

export default {
  props: {
    ueId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ueviLink() {
      return generateShareLink(this.ueId)
    },
  },
  methods: {
    copySnippet() {
      navigator.clipboard.writeText(this.ueviLink)
      this.$emit('copied')
      this.$toast('Share link copied to clipboard')
    },
  },
}
</script>
